








































































import {
  Component,
  Inject,
  InjectReactive,
  Vue, Watch,
} from 'vue-property-decorator';
import { ApiFacade } from '@/services/ApiFacade';
import { User } from '@/models/entities/User';
import { Polygon } from '@/models/entities/Polygon';
import { UserRoleIdsIri, UserRoleIriNames } from '@/types/UserRoleIds';
import { PolygonAcl } from '@/models/entities/PolygonAcl';
import { UserRolePolygonType } from '@/types/UserRolePolygonType';

@Component
export default class PolygonUsersView extends Vue {
  @Inject() readonly apiFacade!: ApiFacade;
  @InjectReactive() polygon!: Polygon | null;
  private isLoading = false;
  private usersRoles: PolygonAcl[] = [];
  private selectedRole: UserRoleIdsIri | 'all' = 'all';

  private searchValue: string = '';

  async mounted() {
    await this.loadPolygonUsers();
  }

  @Watch('polygon')
  async loadPolygonUsers() {
    try {
      this.isLoading = true;
      if (this.polygon) {
        this.usersRoles = await this.apiFacade.fetchUsersRolesOfPolygon(this.polygon);
      }
    } catch (e) {
      console.error('Fetch users error', e);
    } finally {
      this.isLoading = false;
    }
    // this.usersRoles = this.polygon?.userRolePolygons?.filter((r) => r.user && r.userRole) || [];
  }

  get usersRolesFiltered(): PolygonAcl[] {
    const search = this.searchValue.trim()
      .toLocaleLowerCase();
    if (!search && this.selectedRole === 'all') {
      return this.usersRoles;
    }
    return this.usersRoles.filter((rel: PolygonAcl) => {
      const u = rel.user;
      if (this.selectedRole !== 'all' && rel.userRole !== this.selectedRole) {
        return false;
      }
      if (!search) {
        return true;
      }
      return u.fullName.toLowerCase()
        .indexOf(search) !== -1
        || u.email.toLowerCase()
          .indexOf(search) !== -1;
    });
  }

  getUserType(roleId: UserRoleIdsIri) {
    return UserRoleIriNames[roleId];
  }

  listOfPolygons(user: User) {
    return user.userRolePolygons
      .filter((r): r is Required<UserRolePolygonType> => Boolean(r.polygon))
      .map((r) => r.polygon.name)
      .join(', ');
  }
}
